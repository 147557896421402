@import "../variables";
@import "../mixins";
@import "../fonts";
body{
  background-color: white;
}
*{
  box-sizing: border-box;
}
input:focus{
  outline: 1px dashed #f5f5f5;
}
.container-form{
  h4, .title-content{
    font-family: $font-rs-r;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #48464c;
    max-width: 340px;
    margin: 0 auto 28px auto;
    @include max-w($media-xs){
      margin-bottom: 20px;
    }
    b{
      font-family: $font-rs-b;
      font-weight: bold;
    }
  }
}
.incognito-info {
  font-size: 14px; 
  color: #a32235; 
  font-weight: 600;
  line-height: 1.5;
}
.block-info{
  font-size: 12px;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: center;
  color: #605d64;
  padding: 33px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include max-w($media-xs){
    padding-top: 21px;
  }
  strong{
    font-family: $font-r-b;
    font-weight: bold;
  }
  a{
    font-family: $font-r-r;
    color: #605d64;
    &:focus{
      outline: 1px #605d64 dashed;
    }
  }
}
.links-bottom{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 14px;

  a{
    font-family: $font-r-r;
    font-size: 12px;
    color: #605D64;
    &:focus{
      outline: 1px #605d64 dashed;
    }
  }
}
.how-to-use-container {
  text-align: center;
  margin: -20px 0px 10px 0px;
}

.play-icon {
  width: 26px;   // Adjust size as needed
  height: 26px;
  margin-right: 10px;  // Adjust spacing between icon and text
}

.how-to-use-button {
  align-items: center;
  background-color: transparent;
  color: #a32235;
  font-size: 15px;
  font-weight: bold;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  text-decoration: underline;
}

.how-to-use-button:hover {
  text-decoration: underline;
}

.how-to-use-button i {
  margin-right: 8px; /* Spacing between the icon and the text */
}